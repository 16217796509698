import { GatsbyImage } from 'gatsby-plugin-image'
import styled from 'styled-components'
import { legacyBreakpoints } from '../utils/media'
import HoverCard from './HoverCard'
import LinkySpan from './LinkySpan'

const ImageContainer = styled(HoverCard)`
  border-radius: var(--border-radius);
  margin-bottom: 20px;
  box-shadow: var(--box-shadow-primary-small);
  transition: all 250ms;
  cursor: pointer;
  width: 250px;
  height: 353px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  .shadow {
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.34) 1%, rgba(0, 0, 0, 0.54) 100%);
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    opacity: 0;
    z-index: 5;
  }

  ${LinkySpan} {
    opacity: 0;
    position: absolute;
    transition: all 200ms;
    z-index: 5;
  }

  &:hover {
    .shadow {
      opacity: 1;
      transition: all 200ms;
    }

    ${LinkySpan} {
      opacity: 1;
      transition: all 200ms;
    }
  }

  @media screen and (max-width: ${legacyBreakpoints.mobile}) {
    box-shadow: var(--box-shadow-primary-large);
    transform: translateY(-15px);
    margin: 0 auto;

    .shadow {
      opacity: 0.25;
    }

    ${LinkySpan} {
      opacity: 1;
    }
  }
`

const Image = styled(GatsbyImage)`
  margin: 0;
  position: absolute;
  width: 100%;
  height: 100%;
`

const ItemContainer = styled.div`
  margin: 0 10px 40px 10px;
  width: 250px;
  position: relative;
  transition: all 200ms;

  &:last-of-type {
    margin-right: 0;
  }

  @media screen and (max-width: ${legacyBreakpoints.mobile}) {
    width: 100%;
    padding: 15px;
    max-width: 350px;
    text-align: center;
  }
`

const Content = styled.div`
  padding: 0 15px;
`

const Title = styled.span`
  color: var(--color-typography-dark);
  display: block;
  font-weight: 700;
  margin-bottom: 15px;
  text-transform: capitalize;
`

const Description = styled.p`
  font-size: 15px;
  line-height: 25px;
`

const Item = ({ image, imageAlt, imageTitle, title, description, url, noFollow, linkCopy }) => (
  <ItemContainer>
    <ImageContainer
      to={url}
      rel={noFollow ? 'nofollow' : undefined}>
      {image && (
        <Image
          image={image}
          alt={imageAlt}
          title={imageTitle}
        />
      )}
      <div className="shadow" />
      {linkCopy && <LinkySpan>{linkCopy}</LinkySpan>}
    </ImageContainer>
    <Content>
      {title && <Title>{title}</Title>}
      {description && <Description>{description}</Description>}
    </Content>
  </ItemContainer>
)

export default Item
