import { getImage } from 'gatsby-plugin-image'
import { useState } from 'react'
import styled, { css } from 'styled-components'
import Arrow from '../assets/svg/arrow-right.svg?url'
import { useIntl } from '../utils/IntlContext'
import { urlPathJoin } from '../utils/locales'
import { legacyBreakpoints } from '../utils/media'
import Item from './Item'

const View = styled.div`
  width: 100%;
  padding: 0 22.5px;

  .Dropdown-root {
    margin-bottom: 50px;
    transition: all 200ms;
    position: relative;
    cursor: pointer;

    svg {
      width: 10px;
      float: right;
      margin-top: -13px;
      position: absolute;
      right: 20px;
      fill: var(--color-primary);
    }

    &.is-open {
      svg path,
      svg g {
        float: right;
        fill: var(--color-typography-on-primary);
      }
      .Dropdown-control {
        color: var(--color-typography-on-primary);
        background-color: var(--color-background-primary);
        border: none;
        transition: all 200ms;
      }
    }

    .Dropdown-control {
      padding: 14px 40px 14px 20px;
      min-width: 345px;
      box-shadow: var(--box-shadow-black-small);
      color: var(--color-typography-dark);
      background-color: var(--color-background-white);
      border: 1px solid var(--color-light-outline);
      text-align: left;
      border-radius: var(--border-radius);
      margin-bottom: 14pt;
      font-size: 16px;

      &:focus {
        background-color: var(--color-background-primary);
        color: var(--color-typography-on-primary);
      }
    }

    @media screen and (max-width: ${legacyBreakpoints.mobile}) {
      display: block;
    }
  }

  .Dropdown-placeholder {
    display: inline-block;
  }

  .Dropdown-menu {
    margin-top: 5px;
    min-width: 345px;
    padding: 25px 33px;
    border-radius: var(--border-radius);
    background-color: var(--color-background-white);
    box-shadow: var(--box-shadow-black-medium);
    max-height: 400px;
    overflow-y: scroll;
    position: absolute;
    z-index: 10;

    .Dropdown-option {
      margin-bottom: 20px;
      color: var(--color-typography-medium);
      font-size: 16px;

      &:last-of-type {
        margin-bottom: 0;
      }

      &:hover {
        cursor: pointer;
        color: var(--color-typography-primary);
      }
    }
  }
`

const GroupContainer = styled.div`
  ${({ $active }) =>
    $active
      ? css`
          display: block;
        `
      : css`
          display: none;
        `};

  @media screen and (max-width: ${legacyBreakpoints.mobile}) {
    padding: 0;
  }
`

const GroupHeader = styled.div`
  max-width: 679px;
`

const HeaderContainer = styled.div`
  align-items: flex-start;
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: ${legacyBreakpoints.mobile}) {
    flex-direction: column;
    text-align: center;
    align-items: center;
  }
`

const GroupItemsContainer = styled.div`
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
`

const GroupListingSelect = styled.select`
  appearance: none;
  background-color: white;
  border: 1px solid rgb(234, 235, 235);
  border-radius: 4px;
  box-shadow: rgb(0 0 0 / 15%) 0px 2px 4px;
  font-size: 16px;
  text-align: left;
  padding: 14px 40px 14px 20px;
  width: min(90vw, 345px);
  margin-bottom: 14px;
`

const SelectWrapper = styled.div`
  overflow: hidden;
  position: relative;

  &:before {
    background: url(${Arrow}) 0 0 no-repeat;
    background-size: 9px;
    content: '';
    transform: rotate(90deg);
    pointer-events: none;
    position: absolute;
    width: 20px;
    height: 20px;
    top: 22px;
    right: 15px;
    z-index: 1;
  }
`

const TemplateGroupListing = ({ parentBasePath, groups }) => {
  const [activeGroupId, setActiveGroupId] = useState(groups[0].id)
  const { t } = useIntl()

  return (
    <View>
      {groups.map((group) => (
        <GroupContainer
          key={group.id}
          $active={group.id === activeGroupId}>
          <HeaderContainer>
            <GroupHeader>
              {group.title && <h2>{group.title}</h2>}
              {group.description && <p>{group.description}</p>}
            </GroupHeader>
            {groups.length > 1 && (
              <SelectWrapper>
                <GroupListingSelect
                  onChange={(e) => setActiveGroupId(e.target.value)}
                  value={activeGroupId}>
                  {groups.map(({ id, title }) => (
                    <option
                      key={id}
                      value={id}>
                      {title}
                    </option>
                  ))}
                </GroupListingSelect>
              </SelectWrapper>
            )}
          </HeaderContainer>
          <GroupItemsContainer>
            {group.items.map((item) => (
              <Item
                key={item.slug}
                url={urlPathJoin(parentBasePath, item.slug)}
                noFollow={item.noFollow}
                linkCopy={t('view_template')}
                image={getImage(item.template_image.localFile)}
                imageAlt={item.template_image_alt}
                imageTitle={item.template_image_title}
                title={item.name}
                description={item.description.description}
              />
            ))}
          </GroupItemsContainer>
        </GroupContainer>
      ))}
    </View>
  )
}

export default TemplateGroupListing
