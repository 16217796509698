import styled from 'styled-components'

const LinkySpan = styled.span`
  border-radius: var(--border-radius);
  font-weight: 700;
  display: inline-block;
  font-size: 13px;
  cursor: pointer;
  padding: 15px 35px;
  color: var(--color-typography-on-primary);
  background-color: var(--color-background-primary);
  border: 1px solid var(--color-background-primary);
`

export default LinkySpan
